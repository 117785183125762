import { faFacebook, faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import Link from 'next/link'
import { NextRouter, useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../styles/footer.module.scss'
import { Logo, Title } from '../company'
import { ChangeLocale } from '../i18n/ChangeLocale'

const social = [
	{ name: 'Instagram', url: 'http://instagram.com/followfuchsfox' },
	{ name: 'Facebook', url: 'http://facebook.com/followfuchsfox' },
	{ name: 'Twitter', url: 'http://twitter.com/followfuchsfox' },
	{ name: 'Linkedin', url: 'http://linkedin.com/company/fuchsfox' },
	{ name: 'Github', url: 'http://github.com/fuchsfox' },
]

enum Icon {
	Facebook = 'Facebook',
	Twitter = 'Twitter',
	Instagram = 'Instagram',
	Linkedin = 'Linkedin',
	Github = 'Github',
}

const getSocialIcon = (value: string): ReactNode => {
	switch (value) {
		case Icon.Facebook:
			return <FontAwesomeIcon icon={faFacebook} />
		case Icon.Github:
			return <FontAwesomeIcon icon={faGithub} />
		case Icon.Linkedin:
			return <FontAwesomeIcon icon={faLinkedin} />
		case Icon.Twitter:
			return <FontAwesomeIcon icon={faTwitter} />
		case Icon.Instagram:
			return <FontAwesomeIcon icon={faInstagram} />
		default:
			return null
	}
}

export function Footer(): JSX.Element {
	const { locale }: NextRouter = useRouter()
	const { t } = useTranslation(['footer', 'imprint'])
	return (
		<footer className={styles.container}>
			<div className="inner">
				<div className={styles.content}>
					<nav className={styles.left}>
						<span className={styles.copyright}>
							© 2014 - {new Date().getFullYear()} <Title /> {t('rights')}
						</span>
						<ul className={styles.list}>
							<li className={clsx(styles.meta)}>
								<Link key="imprint" href="/policy" scroll passHref>
									<a href="passRef">{t('privacy')}</a>
								</Link>
							</li>
							<li className={clsx(styles.meta)}>
								<Link key="imprint" href="/imprint" scroll passHref>
									<a href="passRef">{t('legal')}</a>
								</Link>
							</li>
						</ul>
					</nav>
					<nav className={styles.center}>
						<ul className={clsx(styles.list, styles.listCenter)}>
							<li className={clsx(styles.contact)}>
								<span>{t('contact:mobile')}: </span>
								<a className={styles.link} href="tel:+4915209001910">
									+49 152 0900 1910
								</a>
							</li>
							<li className={clsx(styles.contact)}>
								<span>{t('contact:email')}: </span>
								<a className={styles.link} href="mailto:hello@fuchsfox.com">
									hello@fuchsfox.com
								</a>
							</li>
						</ul>
					</nav>
					<nav className={styles.right}>
						<ul className={clsx(styles.list, styles.listRight)}>
							<li key={locale} className={clsx(styles.locale)}>
								<ChangeLocale />
							</li>
							{social.map((s, idx) => {
								return (
									<li key={idx} className={clsx(styles.social)}>
										<a
											title={s.name}
											key={idx}
											className={styles.socialItem}
											href={s.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											{getSocialIcon(s.name)}
										</a>
									</li>
								)
							})}
						</ul>
						<Logo color="white" small />
					</nav>
				</div>
			</div>
		</footer>
	)
}
