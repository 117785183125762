import Head from 'next/head'
import React from 'react'

interface Props {
	title: string
	desc: string
	image: string
	username?: string
	type?: string
}

export function Twitter({ type = 'summary_large_image', username = null, title, desc, image }: Props): JSX.Element {
	return (
		<Head>
			{username && <meta name="twitter:creator" content={username} />}
			<meta name="twitter:card" content={type} />
			<meta name="twitter:site" content={'@followfuchsfox'} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={desc} />
			<meta name="twitter:image" content={image} />
			<meta name="twitter:image:alt" content={desc} />
		</Head>
	)
}
