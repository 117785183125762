import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../styles/header.module.scss'
import { Logo } from '../company'

export function Header(): JSX.Element {
	const { t } = useTranslation(['navigation'])
	return (
		<header className={styles.container}>
			<div className="inner">
				<div className={styles.content}>
					<Logo color="white" />
					<nav className={styles.nav}>
						<div className={styles.item}>
							<Link key="work" href="http://instagram.com/followfuchsfox" scroll passHref>
								<a href="passRef" className={styles.link}>
									{t('navigation:work')}
								</a>
							</Link>
						</div>
						<div className={styles.item}>
							<Link key="contact" href="mailto:hello@fuchsfox.com" scroll passHref>
								<a href="passRef" className={styles.link}>
									{t('navigation:contact')}
								</a>
							</Link>
						</div>
					</nav>
				</div>
			</div>
		</header>
	)
}
