import { NextRouter, useRouter } from 'next/router'
import React from 'react'
import styles from '../../styles/changeLocale.module.scss'

export function ChangeLocale(): JSX.Element {
	const { locale, pathname, push }: NextRouter = useRouter()

	const handleOnClick = (): void => {
		const newLocale = locale === 'de' ? 'en' : 'de'
		push(pathname, pathname, { locale: newLocale })
	}

	return (
		<span className={styles.link} onClick={handleOnClick} aria-hidden="true">
			{locale === 'de' ? 'en' : 'de'}
		</span>
	)
}
