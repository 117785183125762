import Head from 'next/head'
import { NextRouter, useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Facebook, Twitter } from '.'

// const site = {
// 	buildTime: '',
// 	metaData: {
// 		siteUrl: '',
// 		defaultBanner: '',
// 		ogLanguage: '',
// 		author: '',
// 	},
// 	firstPublicationDate: '',
// 	lastPublicationDate: '',
// };

interface Banner {
	url: string
	alt: string
}

export interface RichTextSpan {
	start: number
	end: number
	type: string
	text: string
}

export interface RichTextBlock {
	type: string
	text: string
	spans?: RichTextSpan[]
}

export interface Meta {
	title: RichTextBlock[]
	description: RichTextBlock[]
	keyword?: string[] | string
	banner?: Banner
	robot?: string[] | string
}

export function SEO(): JSX.Element {
	const { locale, pathname }: NextRouter = useRouter()
	const { t } = useTranslation(['meta, contact', 'imprint', 'policy', 'about'])
	const siteUrl = locale === 'de' ? 'https://fuchsfox.de' : 'https://fuchsfox.com'
	const title = pathname === '/' ? 'FUCHSFOX' : `FUCHSFOX - ${t(`${pathname.slice(1)}:title`).toUpperCase()}`

	const seo = {
		title,
		description: t('meta:description'),
		image: `https://fuchsfox.com/images/default.png`,
		url: `${siteUrl}${pathname}`,
		author: 'FUCHSFOX',
	}

	const article = false

	// schema.org in JSONLD format
	// https://developers.google.com/search/docs/guides/intro-structured-data
	// You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

	const schemaOrgWebPage = {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		url: siteUrl,
		headline: seo.title,
		inLanguage: `${locale}`,
		mainEntityOfPage: siteUrl,
		description: seo.description,
		name: seo.title,
		author: {
			'@type': 'Person',
			name: seo.author,
		},
		copyrightHolder: {
			'@type': 'Person',
			name: seo.author,
		},
		copyrightYear: '2020',
		creator: {
			'@type': 'Person',
			name: seo.author,
		},
		publisher: {
			'@type': 'Person',
			name: seo.author,
		},
		datePublished: '2019-01-18T10:30:00+01:00',
		dateModified: '2019-01-18T10:30:00+01:00',
		image: {
			'@type': 'ImageObject',
			url: seo.image,
		},
	}

	// Initial breadcrumb list

	// const itemListElement = [
	// 	{
	// 		'@type': 'ListItem',
	// 		item: {
	// 			'@id': siteUrl,
	// 			name: 'Homepage',
	// 		},
	// 		position: 1,
	// 	},
	// 	{
	// 		'@type': 'ListItem',
	// 		item: {
	// 			'@id': `${siteUrl}/categories`,
	// 			name: categories,
	// 		},
	// 		position: 2,
	// 	},
	// ];

	let schemaArticle = null

	if (article) {
		schemaArticle = {
			'@context': 'http://schema.org',
			'@type': 'Article',
			author: {
				'@type': 'Person',
				name: seo.author,
			},
			copyrightHolder: {
				'@type': 'Person',
				name: seo.author,
			},
			copyrightYear: '2019',
			creator: {
				'@type': 'Person',
				name: seo.author,
			},
			publisher: {
				'@type': 'Organization',
				name: seo.author,
				logo: {
					'@type': 'ImageObject',
					url: seo.image,
				},
			},
			datePublished: '2019-01-18T10:30:00+01:00',
			dateModified: '2019-01-18T10:30:00+01:00',
			description: seo.description,
			headline: seo.title,
			inLanguage: `${locale}`,
			url: seo.url,
			name: seo.title,
			image: {
				'@type': 'ImageObject',
				url: seo.image,
			},
			mainEntityOfPage: seo.url,
		}

		// Push current blogpost into breadcrumb list
		// itemListElement.push({
		// 	'@type': 'ListItem',
		// 	item: {
		// 		'@id': seo.url,
		// 		name: seo.title,
		// 	},
		// 	position: 3,
		// });
	}

	// const breadcrumb = {
	// 	'@context': 'http://schema.org',
	// 	'@type': 'BreadcrumbList',
	// 	description: 'Breadcrumbs list',
	// 	name: 'Breadcrumbs',
	// 	itemListElement,
	// };

	return (
		<>
			<Head>
				<title>{seo.title}</title>
				<meta name="description" content={seo.description} />
				<link rel="canonical" href={seo.url} />
				{locale === 'en' ? (
					<link rel="alternate" hrefLang="de" href={`https://fuchsfox.com/de${pathname}`} />
				) : (
					<link rel="alternate" hrefLang="en" href={`https://fuchsfox.com${pathname}`} />
				)}
				{/* <meta name="image" content={seo.image} /> */}
				<meta name="fuchsfox" content="fuchsfox" />
				{/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
				{!article && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
				{/* {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>} */}
				{/* <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script> */}
			</Head>
			<Facebook
				desc={seo.description}
				image={seo.image}
				title={seo.title}
				type={article ? 'article' : 'website'}
				url={seo.url}
				locale={locale}
				name="Facebook"
			/>
			<Twitter title={seo.title} image={seo.image} desc={seo.description} username="@followfuchsfox" />
		</>
	)
}
