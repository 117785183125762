import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import styles from '../../styles/logo.module.scss'

interface Props {
	color: string
	small?: boolean
}

export function Logo({ color, small }: Props): JSX.Element {
	return (
		<Link key="FUCHSFOX-LOGO" href="/" scroll passHref>
			<a href="passRef" className={clsx(styles.link, small ? styles.small : null)}>
				<svg viewBox="0 0 287.475 266.289" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
					<g fill={color}>
						<path d="m109.103 175.71c-1.017-1.018-1.017-2.682 0-3.699l22.19-22.189c1.017-1.018 1.85-3.026 1.85-4.465l.014-42.863c0-1.438-.831-1.783-1.848-.766l-46.247 46.246c-1.017 1.018-2.681 1.018-3.698 0l-12.944-12.948c-1.017-1.018-1.017-2.682 0-3.699l62.896-62.892c1.017-1.017 1.85-3.026 1.85-4.464l.014-61.356c0-1.438-.832-1.783-1.849-.766l-129.482 129.479c-1.017 1.017-1.017 2.681 0 3.698l129.413 129.414c1.017 1.018 1.85.673 1.85-.766l.015-61.322c0-1.438-.832-3.447-1.849-4.465z" />
						<path d="m219.055 135.026c1.017-1.017 1.017-2.681-.001-3.698l-62.891-62.892c-1.018-1.017-1.85-3.026-1.851-4.464l-.014-61.356c-.001-1.438.831-1.783 1.849-.766l129.478 129.479c1.018 1.017 1.018 2.681 0 3.698l-129.408 129.412c-1.018 1.018-1.85.673-1.851-.766l-.018-61.322c-.001-1.438.831-3.447 1.848-4.465z" />
					</g>
				</svg>
			</a>
		</Link>
	)
}
