import Link from 'next/link'
import React from 'react'
import styles from '../../styles/title.module.scss'

export function Title(): JSX.Element {
	return (
		<Link key="FUCHSFOX" href="/" scroll passHref>
			<a href="passRef" className={styles.title}>
				{'FUCHSFOX'.toUpperCase()}
			</a>
		</Link>
	)
}
