import React, { ReactNode } from 'react'
import { Footer } from '../components/footer'
import { Header } from '../components/header'
import { SEO } from '../components/seo'
import styles from '../styles/layout.module.scss'

interface Props {
	children: ReactNode
}

export function Layout({ children }: Props): JSX.Element {
	return (
		<>
			<SEO />
			<Header />
			<div className={styles.container}>{children}</div>
			<Footer />
		</>
	)
}
